import React, { useState, useEffect, useRef } from 'react';
import { Spinner } from 'react-bootstrap';

const LazyImage = ({ url, alt, i, onClick }) => {
    const [isLoaded, setIsLoaded] = useState(false);
    const imgRef = useRef(null);

    useEffect(() => {
        // Create the IntersectionObserver
        const observer = new IntersectionObserver((entries, observer) => {
            entries.forEach(entry => {
                if (entry.isIntersecting) {
                    // Mark image as loaded when it enters the viewport
                    observer.disconnect(); // Stop observing once the image is loaded
                }
            });
        });

        // If the imgRef is not null, observe it
        if (imgRef.current) {
            observer.observe(imgRef.current);
        }

        // Cleanup observer on component unmount
        return () => {
            if (imgRef.current) {
                observer.unobserve(imgRef.current);
            }
        };
    }, [url]); // Re-run when the `url` changes
    const endLoading = () => {
        setIsLoaded(true);
    }
    return (
        <div
            style={{
                width: '50px',
                height: '50px',
                overflow: 'hidden',
                border: '1px solid var(--border)',
                borderRadius: '8px',
                position: "relative"
            }}
        >
            {!isLoaded && (
                <div
                    style={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                    }}
                >
                    <Spinner animation="border" color='#ff5e15' size="sm" />
                </div>
            )}
            {/* Render the placeholder while the image is loading */}
            <img
                ref={imgRef} // Attach the ref to the img element
                src={url} // Show the placeholder initially
                alt={alt || `Attachment ${i + 1}`}
                onLoad={endLoading}
                title={alt || `Attachment ${i + 1}`}
                style={{
                    width: '100%',
                    height: '100%',
                    objectFit: 'cover',
                    cursor: 'pointer',
                }}
                onClick={onClick}
            />
        </div>
    );
};

export default LazyImage;
